<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <table class="kanun" border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td width="">
                <div>
                  <table border="0" width="" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr>
                        <td >
                          <p>5 Kasım 2014 ÇARŞAMBA</p>
                        </td>
                        <td >
                          <p style="text-align: center">
                            <strong>Resmî Gazete</strong>
                          </p>
                        </td>
                        <td >
                          <p style="text-align: right">Sayı : 29166</p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <p style="text-align: center">
                            <strong>KANUN</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <p style="text-align: center">
                            <strong>ELEKTRONİK TİCARETİN DÜZENLENMESİ</strong>
                          </p>
                          <p style="text-align: center">
                            <strong>HAKKINDA KANUN</strong>
                          </p>
                          <p>
                            <strong>
                              <u>Kanun No. 6563 </u>
                              <u>Kabul Tarihi: 23/10/2014</u>
                            </strong>
                          </p>
                          <p>
                            <strong>Amaç ve kapsam</strong>
                          </p>
                          <p>
                            <strong>MADDE 1 -</strong> <strong>(1)</strong> Bu
                            Kanunun amacı, elektronik ticarete ilişkin esas ve
                            usulleri düzenlemektir.
                          </p>
                          <p>
                            <strong>(2)</strong> Bu Kanun, ticari iletişimi,
                            hizmet sağlayıcı ve aracı hizmet
                            sağlayıcıların sorumluluklarını, elektronik iletişim
                            araçlarıyla yapılan sözleşmeler ile elektronik
                            ticarete ilişkin bilgi verme yükümlülüklerini ve
                            uygulanacak yaptırımları kapsar.
                          </p>
                          <p>
                            <strong>(3)</strong>
                            <strong> (Ek:1/7/2022-7416/1 md.)</strong> 14/9/1972 
                            tarihli ve 1618 sayılı Seyahat Acentaları ve Seyahat
                            Acentaları Birliği Kanunu, 14/10/1983 tarihli ve 2920
                            sayılı Türk Sivil Havacılık Kanunu, 28/3/2001
                            tarihli ve 4632 sayılı Bireysel Emeklilik Tasarruf
                            ve Yatırım Sistemi Kanunu, 19/10/2005 tarihli ve
                            5411 sayılı Bankacılık Kanunu, 3/6/2007 tarihli ve
                            5684 sayılı Sigortacılık Kanunu, 21/11/2012 tarihli
                            ve 6361 sayılı Finansal Kiralama, Faktoring,
                            Finansman ve Tasarruf Finansman Şirketleri Kanunu,
                            6/12/2012 tarihli ve 6362 sayılı Sermaye Piyasası
                            Kanunu, 20/6/2013 tarihli ve 6493 sayılı Ödeme ve
                            Menkul Kıymet Mutabakat Sistemleri, Ödeme Hizmetleri
                            ve Elektronik Para Kuruluşları Hakkında Kanun ve
                            5/11/2018 tarihli ve 5809 sayılı Elektronik
                            Haberleşme Kanunuyla kuruluş ve faaliyetleri
                            düzenlenen ve bu kanunlara göre yetkilendirilerek
                            münhasıran yetkilendirildikleri konularda faaliyet
                            gösterenler ile 14/3/2007 tarihli ve 5602 sayılı
                            Şans Oyunları Hasılatından Alınan Vergi, Fon ve
                            Payların Düzenlenmesi Hakkında Kanunun 3 üncü
                            maddesinin birinci fıkrasının (ç) bendinde
                            tanımlananlar ile bunların verdiği yetki dâhilinde
                            münhasıran şans ve bahis oyunları alanında faaliyet
                            gösterenler bu Kanunun uygulanmasında elektronik
                            ticaret aracı hizmet sağlayıcı veya elektronik
                            ticaret hizmet sağlayıcı kabul edilmez.
                          </p>

                          <p>
                            <strong>Tanımlar</strong>
                          </p>
                          <p>
                            <strong>MADDE 2 -</strong> <strong>(1)</strong> Bu
                            Kanunun uygulanmasında;
                          </p>
                          <p>
                            <strong>a)</strong> Elektronik ticaret: Fiziki
                            olarak karşı karşıya gelmeksizin, elektronik ortamda
                            gerçekleştirilen çevrim içi iktisadi ve ticari her
                            türlü faaliyeti,
                          </p>
                          <p>
                            <strong>b)</strong> Ticari iletişim: Alan adları ve
                            elektronik posta adresi dışında, mesleki veya ticari
                            faaliyet kapsamında kazanç sağlamaya yönelik olarak
                            elektronik ticarete ilişkin her türlü iletişimi,
                          </p>
                          <p>
                            <strong>c)</strong> Ticari elektronik ileti:
                            Telefon, çağrı merkezleri, faks, otomatik arama
                            makineleri, akıllı ses kaydedici sistemler,
                            elektronik posta, kısa mesaj hizmeti gibi vasıtalar
                            kullanılarak elektronik ortamda gerçekleştirilen ve
                            ticari amaçlarla gönderilen veri, ses ve görüntü
                            içerikli iletileri,
                          </p>
                          <p>
                            <strong>ç)</strong> Hizmet sağlayıcı: Elektronik
                            ticaret faaliyetinde bulunan gerçek ya da tüzel
                            kişileri,
                          </p>

                          <p>
                            <strong>d)</strong> Aracı hizmet sağlayıcı:
                            Başkalarına ait iktisadi ve ticari faaliyetlerin
                            yapılmasına elektronik ticaret ortamını sağlayan
                            gerçek ve tüzel kişileri,
                          </p>
                          <p>
                            <strong>e)</strong> Bakanlık: Ticaret Bakanlığını,
                          </p>
                          <p>
                            <strong>f)</strong>
                            <strong> (Ek:1/7/2022-7416/2 md.) </strong
                            >Elektronik ticaret aracı hizmet sağlayıcı:
                            Elektronik ticaret pazar yerinde elektronik ticaret
                            hizmet sağlayıcıların mal veya hizmetlerinin
                            teminine yönelik sözleşme yapılmasına ya da sipariş
                            verilmesine imkân sağlayan aracı hizmet sağlayıcıyı,
                          </p>

                          <p>
                            <strong>g)</strong>
                            <strong> (Ek:1/7/2022-7416/2 md.) </strong
                            >Elektronik ticaret hizmet sağlayıcı: Elektronik
                            ticaret pazar yerinde ya da kendine ait elektronik
                            ticaret ortamında mal veya hizmetlerinin teminine
                            yönelik sözleşme yapan ya da sipariş alan hizmet
                            sağlayıcıyı,
                          </p>

                          <p>
                            <strong>ğ)</strong>
                            <strong> (Ek:1/7/2022-7416/2 md.) </strong
                            >Elektronik ticaret ortamı: Elektronik ticaret
                            faaliyetinde bulunulan internet sitesi, mobil site
                            veya mobil uygulama gibi platformları,
                          </p>

                          <p>
                            <strong>h)</strong>
                            <strong> (Ek:1/7/2022-7416/2 md.) </strong
                            >Elektronik ticaret pazar yeri: Elektronik ticaret
                            aracı hizmet sağlayıcının aracılık hizmetlerini
                            sunduğu elektronik ticaret ortamını,
                          </p>

                          <p>
                            <strong>ı)</strong>
                            <strong> (Ek:1/7/2022-7416/2 md.) </strong
                            >Elektronik Ticaret Bilgi Sistemi (ETBİS):Elektronik
                            ticaret hizmet sağlayıcı ve elektronik ticaret aracı
                            hizmet sağlayıcıların kayıt altına
                            alınması, elektronik ticaret verilerinin toplanması,
                            bu verilerin işlenerek istatistiki bilgilerin
                            üretilmesi amacıyla Bakanlık tarafından oluşturulan
                            ve bu Kanun kapsamında kayıt ve bildirim
                            yapılabilmesine imkân sağlayan bilgi sistemini,
                          </p>

                          <p>
                            <strong>i)</strong>
                            <strong> (Ek:1/7/2022-7416/2 md.) </strong>Net işlem
                            hacmi: İptal ve iadeler hariç olmak üzere,
                            elektronik ticaret aracı hizmet sağlayıcı için
                            aracılık hizmeti sunduğu elektronik ticaret pazar
                            yerleri, elektronik ticaret hizmet sağlayıcı için
                            ise elektronik ticaret pazar yeri niteliğini haiz
                            olmayan kendine ait elektronik ticaret ortamları
                            üzerinden belirli bir dönemde yapılan sözleşme ve
                            verilen siparişler için düzenlenmesi gereken nihai
                            fatura veya fatura yerine geçen belge değerleri
                            toplamını,
                          </p>

                          <p>
                            <strong>j)</strong>
                            <strong> (Ek:1/7/2022-7416/2 md.) </strong>Ekonomik
                            bütünlük: Bir kişinin, bir ticaret şirketinin
                            doğrudan veya dolaylı olarak paylarının en az yüzde
                            yirmi beşine ya da oy haklarının çoğunluğuna veya
                            onu yönetebilecek kararları alabilecek payına sahip
                            olmasını, şirket sözleşmesi uyarınca yönetim
                            organında karar alabilecek çoğunluğu oluşturan
                            sayıda üyeyi seçme hakkını haiz olmasını, kendi oy
                            haklarının yanında bir sözleşmeye dayanarak tek
                            başına veya diğer pay sahipleriyle birlikte oy
                            haklarının çoğunluğunu oluşturmasını, bir ticaret
                            şirketini sözleşmeye bağlı olarak hâkimiyeti altında
                            bulundurmasını veya bu hâllere bağlı olarak bu
                            kişiyle bağlantılı tüm ticaret şirketleri ve bu
                            kişiye ait işletmeler arasındaki ilişkiyi ya da pay
                            sahibi olup olmadıklarına bakılmaksızın birden fazla
                            ticaret şirketinin aynı kişi veya kişiler tarafından
                            yönetilmesini,
                          </p>

                          <p>ifade eder.</p>
                          <p>
                            <strong>Bilgi verme yükümlülüğü</strong>
                          </p>
                          <p>
                            <strong>MADDE 3 -</strong>
                            <strong>(1)</strong> Hizmet sağlayıcı, elektronik
                            iletişim araçlarıyla bir sözleşmenin yapılmasından
                            önce;
                          </p>
                          <p>
                            <strong>a)</strong> Alıcıların kolayca ulaşabileceği
                            şekilde ve güncel olarak tanıtıcı bilgilerini,
                          </p>
                          <p>
                            <strong>b)</strong> Sözleşmenin kurulabilmesi için
                            izlenecek teknik adımlara ilişkin bilgileri,
                          </p>
                          <p>
                            <strong>c)</strong> Sözleşme metninin sözleşmenin
                            kurulmasından sonra, hizmet sağlayıcı tarafından
                            saklanıp saklanmayacağı ile bu sözleşmeye alıcının
                            daha sonra erişiminin mümkün olup olmayacağı ve bu
                            erişimin ne kadar süreyle sağlanacağına ilişkin
                            bilgileri,
                          </p>
                          <p>
                            <strong>ç)</strong> Veri girişindeki hataların açık
                            ve anlaşılır bir şekilde belirlenmesine ve
                            düzeltilmesine ilişkin teknik araçlara ilişkin
                            bilgileri,
                          </p>
                          <p>
                            <strong>d)</strong> Uygulanan gizlilik kuralları ve
                            varsa alternatif uyuşmazlık çözüm mekanizmalarına
                            ilişkin bilgileri,
                          </p>
                          <p>sunar.</p>
                          <p>
                            <strong>(2)</strong> Hizmet sağlayıcı, varsa mensubu
                            olduğu meslek odası ile meslekle ilgili davranış
                            kurallarını ve bunlara elektronik olarak ne şekilde
                            ulaşılabileceğini belirtir.
                          </p>
                          <p>
                            <strong>(3)</strong> Tarafların tüketici olmadığı
                            hâllerde taraflar, birinci ve ikinci fıkralardaki
                            düzenlemelerin aksini kararlaştırabilirler.
                          </p>
                          <p>
                            <strong>(4)</strong> Hizmet sağlayıcı, sözleşme
                            hükümlerinin ve genel işlem şartlarının alıcı
                            tarafından saklanmasına imkan sağlar.
                          </p>
                          <p>
                            <strong>(5)</strong> Birinci ve ikinci fıkralar,
                            münhasıran elektronik posta yoluyla veya benzeri
                            bireysel iletişim araçlarıyla yapılan sözleşmelere
                            uygulanmaz.
                          </p>
                          <p>
                            <strong>Sipariş</strong>
                          </p>
                          <p>
                            <strong>MADDE 4 -</strong>
                            <strong>(1)</strong> Elektronik iletişim araçlarıyla
                            verilen siparişlerde aşağıdaki esaslar geçerlidir:
                          </p>
                          <p>
                            <strong>a)</strong> Hizmet sağlayıcı, siparişin
                            onaylanması aşamasında ve ödeme bilgilerinin
                            girilmesinden önce, ödeyeceği toplam bedel de dâhil
                            olmak üzere, sözleşmenin şartlarının alıcı
                            tarafından açıkça görülmesini sağlar.
                          </p>
                          <p>
                            <strong>b)</strong> Hizmet sağlayıcı, alıcının
                            siparişini aldığını gecikmeksizin elektronik
                            iletişim araçlarıyla teyit eder.
                          </p>
                          <p>
                            <strong>c)</strong> Sipariş ve siparişin alındığının
                            teyidi, tarafların söz konusu beyanlara erişiminin
                            mümkün olduğu anda gerçekleşmiş sayılır.
                          </p>
                          <p>
                            <strong>(2)</strong> Hizmet sağlayıcı, sipariş
                            verilmeden önce alıcıya, veri giriş hatalarını
                            belirleyebilmesi ve düzeltebilmesi için uygun,
                            etkili ve erişilebilir teknik araçları sunar.
                          </p>
                          <p>
                            <strong>(3)</strong> Tarafların tüketici olmadığı
                            hâllerde taraflar, birinci ve ikinci fıkralardaki
                            düzenlemelerin aksini kararlaştırabilirler.
                          </p>
                          <p>
                            <strong>(4)</strong> Birinci fıkranın (<strong
                              >a)</strong
                            >
                            ve (<strong>b)</strong> bentleri ile ikinci fıkra,
                            münhasıran elektronik posta yoluyla veya benzeri
                            bireysel iletişim araçlarıyla yapılan sözleşmelere
                            uygulanmaz.
                          </p>
                          <p>
                            <strong>Ticari iletişime ilişkin esaslar</strong>
                          </p>
                          <p>
                            <strong>MADDE 5 -</strong>
                            <strong>(1)</strong> Ticari iletişimde:
                          </p>
                          <p>
                            <strong>a)</strong> Ticari iletişimin ve bu
                            iletişimin adına yapıldığı gerçek ya da tüzel
                            kişinin açıkça belirlenebilir olmasını sağlayan
                            bilgiler sunulmalıdır.
                          </p>
                          <p>
                            <strong>b)</strong> İndirim ve hediye gibi
                            promosyonlar ile promosyon amaçlı yarışma veya
                            oyunların bu niteliği açıkça belirlenebilmeli,
                            bunlara katılımın ve bunlardan faydalanmanın
                            şartlarına kolayca ulaşılabilmeli ve bu şartlar açık
                            ve şüpheye yer bırakmayacak şekilde anlaşılır
                            olmalıdır.
                          </p>
                          <p>
                            <strong
                              >Ticari elektronik ileti gönderme şartı</strong
                            >
                          </p>
                          <p>
                            <strong>MADDE 6 -</strong>
                            <strong>(1)</strong> Ticari elektronik iletiler,
                            alıcılara ancak önceden onayları alınmak kaydıyla
                            gönderilebilir. Bu onay, yazılı olarak veya her
                            türlü elektronik iletişim araçlarıyla alınabilir.
                            Kendisiyle iletişime geçilmesi amacıyla alıcının
                            iletişim bilgilerini vermesi hâlinde, temin edilen
                            mal veya hizmetlere ilişkin değişiklik, kullanım ve
                            bakıma yönelik ticari elektronik iletiler için
                            ayrıca onay alınmaz.
                          </p>
                          <p>
                            <strong>(2)</strong> Esnaf ve tacirlere önceden onay
                            alınmaksızın ticari elektronik iletiler
                            gönderilebilir.
                          </p>
                          <p>
                            <strong>Ticari elektronik iletinin içeriği</strong>
                          </p>
                          <p>
                            <strong>MADDE 7 -</strong>
                            <strong>(1)</strong> Ticari elektronik iletinin
                            içeriği, alıcıdan alınan onaya uygun olmalıdır.
                          </p>
                          <p>
                            <strong>(2)</strong> İletide, hizmet sağlayıcının
                            tanınmasını sağlayan bilgiler ile haberleşmenin
                            türüne bağlı olarak telefon numarası, faks numarası,
                            kısa mesaj numarası ve elektronik posta adresi gibi
                            erişilebilir durumdaki iletişim bilgileri yer alır.
                          </p>
                          <p>
                            <strong>(3)</strong> İletide, haberleşmenin türüne
                            bağlı olarak, iletinin konusu, amacı ve başkası
                            adına yapılması hâlinde kimin adına yapıldığına
                            ilişkin bilgilere de yer verilir.
                          </p>
                          <p>
                            <strong
                              >Alıcının ticari elektronik iletiyi reddetme
                              hakkı</strong
                            >
                          </p>
                          <p>
                            <strong>MADDE 8 -</strong>
                            <strong>(1)</strong> Alıcılar diledikleri zaman,
                            hiçbir gerekçe belirtmeksizin ticari elektronik
                            iletileri almayı reddedebilir.
                          </p>
                          <p>
                            <strong>(2)</strong> Hizmet sağlayıcı ret
                            bildiriminin, elektronik iletişim araçlarıyla kolay
                            ve ücretsiz olarak iletilmesini sağlamakla ve
                            gönderdiği iletide buna ilişkin gerekli bilgileri
                            sunmakla yükümlüdür.
                          </p>
                          <p>
                            <strong>(3)</strong> Talebin ulaşmasını müteakip
                            hizmet sağlayıcı üç iş günü içinde alıcıya
                            elektronik ileti göndermeyi durdurur.
                          </p>
                          <p>
                            <strong>Hukuka aykırı içerik </strong>
                          </p>
                          <p>
                            <strong>MADDE 9 -</strong>
                            <strong>(1)</strong
                            ><strong>
                              (Başlığı ile Birlikte Değişik:1/7/2022-7416/3 md.)
                            </strong>
                            Diğer kanunlarda aksine hüküm bulunmadıkça, aracı
                            hizmet sağlayıcı, hizmet sağlayıcı tarafından
                            sunulan içerik ve içeriğe konu mal veya hizmetle
                            ilgili hukuka aykırı hususlardan sorumlu değildir.
                          </p>
                          <p>
                            <strong>(2)</strong> Elektronik ticaret aracı hizmet
                            sağlayıcı, elektronik ticaret hizmet sağlayıcı
                            tarafından sunulan içeriğin hukuka aykırı olduğundan
                            haberdar olması hâlinde, bu içeriği gecikmeksizin
                            yayımdan kaldırarak hukuka aykırı hususu ilgili kamu
                            kurum ve kuruluşlarına bildirir.
                          </p>
                          <p>
                            <strong>(3)</strong> Elektronik ticaret aracı hizmet
                            sağlayıcı, hak sahibinin, fikri ve sınai mülkiyet
                            hakkı ihlaline dair bilgi ve belgeye dayanan
                            şikâyeti üzerine, elektronik ticaret hizmet
                            sağlayıcının şikâyete konu ürününü yayımdan
                            kaldırarak durumu kendisine ve hak sahibine
                            bildirir. Elektronik ticaret hizmet sağlayıcının,
                            şikâyetin aksini gösteren bilgi ve belgeye dayanan
                            itirazını elektronik ticaret aracı hizmet
                            sağlayıcıya sunması üzerine şikâyete konu ürünü
                            yeniden yayımlar. Şikâyet ve itirazda; ilgililerin
                            açık kimlik ve adres bilgileri, ihtilafa konu ürün
                            hakkındaki bilgiler, ürünün yayımdan kaldırılması
                            veya yayımlanmasının gerekliliğine dair gerekçeler
                            ile yönetmelikle belirlenen diğer hususlar yer alır.
                            İlgililerin genel hükümlere göre adli ve idari
                            mercilere başvurma hakları saklıdır.
                          </p>
                          <p>
                            <strong>Kişisel verilerin korunması</strong>
                          </p>
                          <p>
                            <strong>MADDE 10 -</strong>
                            <strong> (Mülga:1/7/2022-7416/4 md.) </strong>
                          </p>

                          <p>
                            <strong>Bakanlık yetkisi</strong>
                          </p>
                          <p>
                            <strong>MADDE 11 -</strong>
                            <strong>(1)</strong>
                            <strong> (Değişik:1/7/2022-7416/5 md.)  </strong>
                            Bakanlık, bu Kanunun uygulanmasını ve elektronik
                            ticaretin gelişimini sağlamaya, etkin ve adil
                            rekabet ortamını korumaya, hizmet sağlayıcı ve aracı
                            hizmet sağlayıcının faaliyetlerine yönelik düzenleme
                            yapmaya ve aracılık sözleşmesinde yer verilmesi
                            zorunlu unsurları belirlemeye yetkilidir.
                          </p>
                          <p>
                            <strong>(2)</strong>
                            <strong> (Ek:1/7/2022-7416/5 md.)  </strong>
                            Bakanlık, bu Kanunun uygulanmasını sağlamak amacıyla
                            denetim yapmaya ve denetim sonuçlarını açıklamaya,
                            denetim sırasında özel uzmanlık veya teknik bilgi
                            gerektiren durumlarda bilirkişi görevlendirmeye,
                            bilirkişinin hak ve yükümlülükleri ile
                            görevlendirilmesine ilişkin usul ve esasları
                            yönetmelikle belirlemeye yetkilidir. Bilirkişiye
                            verilecek ücretin tutarı Bakanlık ile Hazine ve
                            Maliye Bakanlığınca birlikte tespit olunur ve bu
                            ücret Bakanlık bütçesine konulacak ödenekten
                            karşılanır.
                          </p>
                          <p>
                            <strong>(3)</strong> Bakanlıkça denetimle
                            görevlendirilen personel, bu Kanun kapsamında
                            Bakanlık yetkisine giren hususlarla ilgili olarak
                            elektronik ortamda tutulanlar dâhil olmak üzere her
                            türlü bilgi, belge ve defterleri istemeye, bunları
                            incelemeye ve örneklerini almaya, ilgililerden
                            yazılı ve sözlü bilgi almaya yetkili olup ilgililer
                            istenilen bilgi, belge ve defterler ile elektronik
                            kayıtlarını, bunların örneklerini noksansız ve
                            gerçeğe uygun olarak vermek, yazılı ve sözlü bilgi
                            taleplerini karşılamak ve her türlü yardım ve
                            kolaylığı göstermekle yükümlüdür.<strong
                              > (Ek cümle:1/7/2022-7416/5 md.) </strong
                            >Aracı hizmet sağlayıcı ve hizmet sağlayıcı, bu
                            Kanun kapsamındaki iş ve işlemlerine ilişkin bilgi,
                            belge, defter ve elektronik kayıtlarını, iş veya
                            işlemin gerçekleştiği tarihten itibaren on yıl
                            süreyle saklamakla yükümlüdür.
                          </p>
                          <p>
                            <strong>(4)</strong>
                            <strong> (Ek:28/11/2017-7061/117 md.) </strong> Kamu
                            kurum ve kuruluşları, kamu kurumu niteliğindeki
                            meslek kuruluşları ve diğer gerçek veya tüzel
                            kişiler, elektronik ticaretin gelişiminin
                            izlenebilmesi ve değerlendirilebilmesi amacıyla
                            Bakanlık tarafından istenilen bilgileri Bakanlıkça
                            oluşturulan sisteme bildirir.
                          </p>

                          <p>
                            <strong>(5)</strong>
                            <strong> (Ek:28/11/2017-7061/117 md.) </strong>
                            Bakanlık, ticari elektronik ileti onaylarının
                            alınmasına ve reddetme hakkının kullanılmasına imkân
                            tanıyan bir elektronik sistem kurmaya veya
                            kurdurmaya yetkilidir. Bu Kanun çerçevesinde alınan
                            onaylar Bakanlıkça belirlenen süre içinde sisteme
                            aktarılır. Sisteme aktarılmayan onaylar geçersiz
                            kabul edilir. Alıcılar, sisteme kaydedilen onayları
                            Bakanlıkça belirlenen süre içinde kontrol eder. Bu
                            sürenin bitiminden sonra gönderilen ticari
                            elektronik iletiler onaylı kabul edilir. Alıcılar
                            tarafından reddetme hakkı bu sistem üzerinden
                            kullanılır. Sistemin kurulması, onayların sisteme
                            aktarılması, saklanması, reddetme hakkının sistem
                            üzerinden kullanılması ve sistemin işleyişine
                            ilişkin diğer usul ve esaslar yönetmelikle
                            belirlenir.
                          </p>

                          <p>
                            <strong>(6)</strong>
                            <strong> (Ek:1/7/2022-7416/5 md.) </strong>
                            Bakanlık, 3 üncü, 4 üncü, 5 inci, 6 ncı, 7 nci ve 8
                            inci maddelerde düzenlenen yükümlülüklerin aracı
                            hizmet sağlayıcılara uygulanmasına ilişkin usul ve
                            esasları belirlemeye yetkilidir.
                          </p>
                          <p>
                            <strong>(7)</strong>
                            <strong> (Ek:1/7/2022-7416/5 md.) </strong>
                            Bakanlık, sesli arama ve kısa mesaj yoluyla ticari
                            elektronik ileti gönderen gerçek veya tüzel kişi
                            abone bilgilerini Bilgi Teknolojileri ve İletişim
                            Kurumundan almaya yetkilidir.
                          </p>

                          <p>
                            <strong>Cezai hükümler</strong>
                          </p>
                          <p>
                            <strong>MADDE 12 -</strong> <strong>(1)</strong>
                            <strong> (Değişik:1/7/2022-7416/6 md.) </strong> Bu
                            Kanunun;
                          </p>
                          <p>
                            <strong>a)</strong> 3 üncü maddesindeki
                            yükümlülüklere, 4 üncü maddesinin birinci fıkrasının
                            (a) bendindeki yükümlülüklere, 6 ncı maddesinin
                            birinci fıkrasına veya 7 nci maddesinin birinci
                            fıkrasına aykırı hareket eden hizmet sağlayıcılara
                            ve aracı hizmet sağlayıcılara bin Türk lirasından
                            beş bin Türk lirasına kadar,
                          </p>
                          <p>
                            <strong>b)</strong> 4 üncü maddesinin birinci
                            fıkrasının (b) bendindeki veya aynı maddenin ikinci
                            fıkrasındaki, 5 inci maddesinin birinci fıkrasının
                            (a) bendindeki veya 7 nci maddesinin ikinci ve
                            üçüncü fıkralarındaki yükümlülüklere aykırı hareket
                            eden hizmet sağlayıcılara ve aracı hizmet
                            sağlayıcılara bin Türk lirasından on bin Türk
                            lirasına kadar,
                          </p>
                          <p>
                            <strong>c)</strong> 5 inci maddesinin birinci
                            fıkrasının (b) bendindeki, 8 inci maddesinin ikinci
                            ve üçüncü fıkralarındaki yükümlülüklere aykırı
                            hareket eden hizmet sağlayıcılara ve aracı hizmet
                            sağlayıcılara iki bin Türk lirasından on beş bin
                            Türk lirasına kadar,
                          </p>
                          <p>
                            <strong>ç)</strong> 9 uncu maddesinin ikinci ve
                            üçüncü fıkraları, ek 1 inci maddesinin ikinci
                            fıkrasının (ç) bendi ve ek 2 nci maddesinin birinci
                            fıkrasının (c) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya ve ek 3
                            üncü maddesinin birinci fıkrasına aykırı hareket
                            eden elektronik ticaret hizmet sağlayıcıya, her bir
                            aykırılık için on bin Türk lirasından yüz bin Türk
                            lirasına kadar,
                          </p>
                          <p>
                            <strong>d)</strong> 11 inci maddesinin birinci
                            fıkrası kapsamında Bakanlıkça yapılan ikincil
                            düzenlemelere uymayanlara her bir aykırılık için on
                            bin Türk lirasından yüz bin Türk lirasına kadar,
                          </p>
                          <p>
                            <strong>e)</strong> 11 inci maddesinin üçüncü
                            fıkrasına aykırı hareket edenlere elli bin Türk
                            lirasından iki yüz elli bin Türk lirasına kadar,
                          </p>
                          <p>
                            <strong>f)</strong> 11 inci maddesinin dördüncü
                            fıkrasına aykırı hareket eden gerçek kişiler ile
                            özel hukuk tüzel kişilerine elli bin Türk lirasından
                            iki yüz elli bin Türk lirasına kadar,
                          </p>
                          <p>
                            <strong>g)</strong> Ek 1 inci maddesinin birinci
                            fıkrasına aykırı hareket eden elektronik ticaret
                            aracı hizmet sağlayıcıya, haksız uygulamada
                            bulunulan her bir elektronik ticaret hizmet
                            sağlayıcı için on bin Türk lirasından yüz bin Türk
                            lirasına kadar,
                          </p>

                          <p>
                            <strong>ğ)</strong> Ek 1 inci maddesinin ikinci
                            fıkrasının (a) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya,
                            süresinde ödeme yapılmayan her bir elektronik
                            ticaret hizmet sağlayıcı için on bin Türk lirasından
                            az olmamak üzere, otuz güne kadarki süre aşımlarında
                            ödenmeyen tutar kadar, otuz günü geçen süre
                            aşımlarında ise süresinde ödenmeyen tutarın iki
                            katı,
                          </p>

                          <p>
                            <strong>h)</strong> Ek 1 inci maddesinin ikinci
                            fıkrasının (b) bendine ve ek 2 nci maddesinin ikinci
                            fıkrasının (b) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya, her bir
                            elektronik ticaret hizmet sağlayıcı için beş yüz bin
                            Türk lirası,
                          </p>

                          <p>
                            <strong>ı)</strong> Ek 1 inci maddesinin ikinci
                            fıkrasının (c) bendine aykırı hareket eden ve
                            Bakanlık tarafından yapılan uyarıya rağmen
                            aykırılığı ortadan kaldırmayan elektronik ticaret
                            aracı hizmet sağlayıcıya, her bir aykırılık için on
                            bin Türk lirası,
                          </p>

                          <p>
                            <strong>i)</strong> Ek 1 inci maddesinin ikinci
                            fıkrasının (d) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya, haksız
                            olarak alınan bedelin on katı,
                          </p>

                          <p>
                            <strong>j)</strong> Ek 1 inci maddesinin ikinci
                            fıkrasının (e) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya, haksız
                            uygulamada bulunulan her bir elektronik ticaret
                            hizmet sağlayıcı için üç yüz bin Türk lirası,
                          </p>

                          <p>
                            <strong>k)</strong> Ek 2 nci maddesinin birinci
                            fıkrasının (a) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya,
                            aykırılığın gerçekleştiği tarihten bir önceki takvim
                            yılına ait net satış tutarının yüzde beşi oranında,
                          </p>

                          <p>
                            <strong>l)</strong> Ek 2 nci maddesinin birinci
                            fıkrasının (b) bendi ile ikinci fıkrasının (f)
                            bendine aykırı hareket eden elektronik ticaret aracı
                            hizmet sağlayıcıya, yüz bin Türk lirasından az
                            olmamak üzere, aykırılığın gerçekleştiği tarihten
                            bir önceki takvim yılına ait net satış tutarının on
                            binde beşi oranında,
                          </p>

                          <p>
                            <strong>m)</strong> Ek 2 nci maddesinin birinci
                            fıkrasının (ç) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya, her bir
                            aykırılık için on bin Türk lirası,
                          </p>

                          <p>
                            <strong>n)</strong> Ek 2 nci maddesinin ikinci
                            fıkrasının (a) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya,
                            aykırılığın gerçekleştiği tarihten bir önceki takvim
                            yılına ait net satış tutarının yüzde onuna kadar,
                          </p>

                          <p>
                            <strong>o)</strong> Ek 2 nci maddesinin ikinci
                            fıkrasının (c) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcı ve
                            elektronik ticaret hizmet sağlayıcıya on milyon Türk
                            lirası,
                          </p>

                          <p>
                            <strong>ö)</strong> Ek 2 nci maddesinin ikinci
                            fıkrasının (ç) ve (d) bentlerine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya ve
                            elektronik ticaret hizmet sağlayıcıya süresi içinde
                            yapılmayan her bir bildirim için bir milyon Türk
                            lirası,
                          </p>

                          <p>
                            <strong>p)</strong> Ek 2 nci maddesinin ikinci
                            fıkrasının (e) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcı ve
                            elektronik ticaret hizmet sağlayıcıya bir milyon
                            Türk lirası,
                          </p>

                          <p>
                            <strong>r)</strong> Ek 2 nci maddesinin üçüncü
                            fıkrasının (a) ve (b) bentleri
                            ile onikinci fıkrasına ve ek 3 üncü maddesinin
                            yedinci fıkrasına aykırı hareket eden elektronik
                            ticaret aracı hizmet sağlayıcıya, elektronik ticaret
                            hizmet sağlayıcıya ve mal veya hizmet ilanlarının
                            yayımlanmasına elektronik ortam sağlayanlara anılan
                            hükümlere göre belirlenen üst sınırın yüzde onundan
                            az olmamak kaydıyla sınırı aşan tutarın on katı,
                          </p>

                          <p>
                            <strong>s)</strong> Ek 2 nci maddesinin üçüncü
                            fıkrasının (c) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya, haksız
                            uygulamada bulunulan her bir elektronik ticaret
                            hizmet sağlayıcı için iki yüz elli bin Türk lirası,
                          </p>

                          <p>
                            <strong>ş)</strong> Ek 2 nci maddesinin dördüncü
                            fıkrasının (ç) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcı ve
                            elektronik ticaret hizmet sağlayıcıya yirmi milyon
                            Türk lirası,
                          </p>

                          <p>idari para cezası verilir.</p>
                          <p>
                            <strong>(2)</strong> Bir defada birden fazla kimseye
                            6 ncımaddenin birinci fıkrasına aykırı olarak ileti
                            gönderilmesi hâlinde, birinci fıkranın (a) bendinde
                            öngörülen idari para cezası on katına kadar
                            artırılarak uygulanır.
                          </p>
                          <p>
                            <strong>(3)</strong>
                            <strong> (Ek:1/7/2022-7416/6 md.)  </strong> Bu
                            Kanunun ek 2 nci maddesinin dördüncü fıkrasının (a)
                            ve (b) bentleri ile ek 4 üncü maddesinin birinci
                            fıkrasına aykırı hareket eden elektronik ticaret
                            aracı hizmet sağlayıcı ve elektronik ticaret hizmet
                            sağlayıcılar ile ek 2 nci maddesinin dördüncü
                            fıkrasının (c) bendine aykırı hareket eden
                            elektronik ticaret aracı hizmet sağlayıcıya on
                            milyon Türk lirası idari para cezası uygulanarak
                            aykırılığın ortadan kaldırılması için altmış gün
                            süre verilir. Bu süre içinde aykırılığın ortadan
                            kaldırılmaması hâlinde yirmi milyon Türk lirası
                            idari para cezası uygulanarak aykırılığın ortadan
                            kaldırılması için otuz gün süre verilir. Verilen
                            süre içinde aykırılığın ortadan kaldırılmaması
                            hâlinde kırk milyon Türk lirası idari para cezası
                            uygulanarak aykırılığın ortadan kaldırılması için on
                            beş gün süre verilir. Bu sürenin verilmesine yönelik
                            bildirimde, on beş günlük süre içinde aykırılığın
                            ortadan kaldırılmaması hâlinde Bakanlıkça, içeriğin
                            çıkarılması ve/veya erişimin engellenmesi kararı
                            verilebileceği belirtilir. Bu bildirimden itibaren
                            yalnızca geçmiş siparişlere ilişkin iş ve işlemler
                            yürütülür. Verilen süre içinde aykırılığın ortadan
                            kaldırılmaması hâlinde, Bakanlık tarafından
                            elektronik ticaret aracı hizmet sağlayıcının ve/veya
                            elektronik ticaret hizmet sağlayıcının ilgili
                            internet adreslerine içeriğin çıkarılması ve/veya
                            erişimin engellenmesi kararı verilebilir. Bu karar,
                            Erişim Sağlayıcıları Birliğine gönderilir. Birlik
                            tarafından ilgili içerik ve yer sağlayıcılar ile
                            erişim sağlayıcılara gönderilen içeriğin çıkarılması
                            ve/veya erişimin engellenmesi kararlarının gereği
                            ilgili içerik ve yer sağlayıcılar ile erişim
                            sağlayıcılar tarafından derhal yerine getirilir.
                            İçeriğin çıkarılması ve/veya erişimin engellenmesi
                            kararına karşı sulh ceza hâkimliğine başvurulabilir.
                            Sulh ceza hâkimliğince verilen karara
                            karşı 4/12/2004 tarihli ve 5271 sayılı Ceza
                            Muhakemesi Kanunu hükümlerine göre itiraz
                            edilebilir.
                          </p>

                          <p>
                            <strong>(4)</strong>
                            <strong> (Ek:1/7/2022-7416/6 md.)   </strong>
                            Birinci fıkrada belirtilen idari para cezalarının
                            uygulanmasına rağmen, Bakanlıkça verilen süre içinde
                            aykırılığın sonlandırılmaması veya aynı aykırılığın
                            ceza tebligatının yapıldığı tarihten itibaren bir
                            yıl içinde tekrarı hâlinde bir önceki cezanın iki
                            katı idari para cezası uygulanır.
                          </p>

                          <p>
                            <strong>(5)</strong>
                            <strong> (Ek:1/7/2022-7416/6 md.)  </strong>
                            Birinci fıkrada belirtilen idari para cezalarına
                            ilişkin fiillerin, Bakanlığı yanıltmaya yönelik iş
                            ve işlemlerle gerçekleştirilmesi hâlinde söz konusu
                            cezalar on katı uygulanır.
                          </p>

                          <p>
                            <strong>(6)</strong>
                            <strong> (Ek:1/7/2022-7416/6 md.)  </strong>
                            Bu maddede belirtilen idari para cezalarının bir
                            takvim yılına ilişkin toplam tutarı, net işlem hacmi
                            ek 2 nci maddenin dördüncü fıkrasının birinci
                            cümlesinde yer alan parasal eşiğin altında olan
                            aracı hizmet sağlayıcı veya hizmet sağlayıcı için
                            beş yüz milyon Türk lirasını geçemez. Bu hüküm,
                            beşinci fıkra kapsamında idari yaptırım uygulananlar
                            hakkında uygulanmaz.
                          </p>

                          <p>
                            <strong>(7)</strong>
                             Bu maddede öngörülen idari para cezalarını verme
                            yetkisi Bakanlığa aittir. Bu yetki, merkezde
                            Bakanlığın ilgili genel müdürlüğüne, taşrada ise
                            Bakanlığın il müdürlüklerine devredilebilir.
                          </p>

                          <p>
                            <strong>Yönetmelikler</strong>
                          </p>
                          <p>
                            <strong>MADDE 13 -</strong> <strong>(1)</strong> Bu
                            Kanunun uygulanmasına ilişkin yönetmelikler; Adalet
                            Bakanlığı, Maliye Bakanlığı, Ulaştırma, Denizcilik
                            ve Haberleşme Bakanlığı ve Ekonomi Bakanlığı ile
                            Bilgi Teknolojileri ve İletişim Kurumunun görüşleri
                            alınarak Bakanlık tarafından hazırlanır.
                          </p>
                          <p>
                            <strong>Değiştirilen mevzuat</strong>
                          </p>
                          <p>
                            <strong>MADDE 14 -</strong>
                            <strong>(1)</strong>
                            <strong>
                              (5/11/2008 tarihli ve 5809 sayılı Elektronik
                              Haberleşme Kanunu ile ilgili olup yerine
                              işlenmiştir.)
                            </strong>
                          </p>
                          <p>
                            <strong
                              >Elektronik ticarette haksız ticari
                              uygulamalar</strong
                            >
                          </p>
                          <p>
                            <strong
                              >EK MADDE 1-  (Ek:1/7/2022-7416/7 md.)</strong
                            >
                          </p>
                          <p>
                            <strong> (1) </strong>
                            Elektronik ticarette haksız ticari uygulamada
                            bulunulamaz. Elektronik ticaret aracı hizmet
                            sağlayıcının, aracılık hizmeti sunduğu elektronik
                            ticaret hizmet sağlayıcının ticari faaliyetlerini
                            önemli ölçüde bozan, makul karar verme yeteneğini
                            azaltan veya belirli bir kararı almaya zorlayarak
                            normal şartlarda taraf olmayacağı bir ticari
                            ilişkinin tarafı olmasına sebep olan uygulamalarının
                            haksız olduğu kabul edilir.
                          </p>
                          <p>
                            <strong> (2) </strong>
                            Aşağıdaki uygulamalar her durumda haksız ticari
                            uygulama sayılır:
                          </p>
                          <p>
                            <strong> a) </strong>
                            Mal veya hizmet satışı karşılığında elektronik
                            ticaret hizmet sağlayıcıya yapılması gereken
                            ödemenin, en geç satış bedelinin elektronik ticaret
                            aracı hizmet sağlayıcının tasarrufuna girdiği ve
                            siparişin alıcıya ulaştığı tarihten itibaren beş iş
                            günü içinde eksiksiz yapılmaması.
                          </p>

                          <p>
                            <strong> b) </strong>
                            Elektronik ticaret aracı hizmet sağlayıcı tarafından
                            satış fiyatında tek taraflı değişiklik yapılması
                            dâhil olmak üzere, elektronik ticaret hizmet
                            sağlayıcının kampanyalı mal veya hizmet satışına
                            zorlanması.
                          </p>

                          <p>
                            <strong> c) </strong>
                            Elektronik ticaret hizmet sağlayıcıyla olan ticari
                            ilişkinin koşullarının, yazılı şekilde veya
                            elektronik ortamda yapılan aracılık sözleşmesiyle
                            belirlenmemesi ya da bu sözleşmenin açık, anlaşılır
                            ve elektronik ticaret hizmet sağlayıcı tarafından
                            kolay erişilebilir olmasının sağlanmaması.
                          </p>

                          <p>
                            <strong> ç) </strong>
                            Elektronik ticaret hizmet sağlayıcının aleyhine
                            olacak şekilde aracılık sözleşmesi hükümlerinde
                            geçmişe yönelik veya tek taraflı değişiklik
                            yapılması ya da buna imkân sağlayan herhangi bir
                            hükme aracılık sözleşmesinde yer verilmesi.
                          </p>

                          <p>
                            <strong> d) </strong>
                            Herhangi bir hizmet verilmediği veya verilen
                            hizmetin türü ve hizmet bedelinin tutar ya da oranı
                            aracılık sözleşmesinde belirtilmediği hâlde
                            elektronik ticaret hizmet sağlayıcıdan bedel
                            alınması.
                          </p>

                          <p>
                            <strong> e) </strong>
                            Aracılık sözleşmesinde herhangi bir nesnel ölçüte
                            yer verilmediği hâlde ya da kamu kurumlarına veya
                            adli mercilere başvuruda bulunulduğu gerekçesiyle
                            elektronik ticaret hizmet sağlayıcının sıralama ya
                            da tavsiye sisteminde geriye düşürülmesi, elektronik
                            ticaret hizmet sağlayıcıya sunulan hizmetin
                            kısıtlanması, askıya alınması veya sonlandırılması.
                          </p>

                          <p>
                            <strong> (3) </strong>
                            İkinci fıkranın (a) bendinin uygulanmasında,
                            elektronik ticaret aracı hizmet sağlayıcının
                            ekonomik bütünlük içinde bulunduğu ödeme hizmeti
                            sağlayıcısına yapılan ödemeler, elektronik ticaret
                            aracı hizmet sağlayıcıya yapılmış kabul edilir.
                          </p>

                          <p>
                            <strong
                              >Elektronik ticaret aracı hizmet sağlayıcının
                              yükümlülükleri
                            </strong>
                          </p>
                          <p>
                            <strong
                              >EK MADDE 2-  (Ek:1/7/2022-7416/8 md.)
                            </strong>
                          </p>

                          <p>
                            <strong> (1) </strong>
                            Elektronik ticaret aracı hizmet sağlayıcı aşağıdaki
                            hususlara uymakla yükümlüdür:
                          </p>

                          <p>
                            <strong> a) </strong>
                            Aracılık hizmeti sunduğu elektronik ticaret pazar
                            yerlerinde kendisinin veya ekonomik bütünlük içinde
                            bulunduğu kişilerin markasını taşıyan ya da marka
                            kullanım hakkını haiz olduğu malları satışa sunamaz
                            veya bu malların satışına aracılık edemez. Bu
                            malların, farklı elektronik ticaret ortamında satışa
                            sunulması hâlinde bu ortamlar arasında erişim imkânı
                            sunamaz ve birbirinin tanıtımını yapamaz. Bu hüküm,
                            toplam satış hasılatının yarısından fazlasını
                            elektronik ticaret dışındaki satışlardan elde eden
                            kişilerin markasını taşıyan veya marka kullanım
                            hakkını haiz olduğu mallar hakkında uygulanmaz.
                          </p>

                          <p>
                            <strong> b) </strong>
                            Elektronik ticaret hizmet
                            sağlayıcının 4/1/1961 tarihli ve 213 sayılı Vergi
                            Usul Kanunu kapsamında düzenlemesi gereken
                            belgelerdeki bilgilere, satışın yapıldığı elektronik
                            ticaret pazar yerinde yer verebilmesine imkân
                            sağlar.
                          </p>

                          <p>
                            <strong> c) </strong>
                            Elektronik ticaret aracı hizmet sağlayıcı veya
                            elektronik ticaret hizmet sağlayıcının yazılı
                            şekilde ya da elektronik ortamda önceden olumlu
                            irade beyanını almadan bunların ETBİS’e kayıtlı alan
                            adlarının ana unsurunu oluşturan tescilli
                            markalarını kullanarak çevrim içi arama motorlarında
                            pazarlama ve tanıtım faaliyetinde bulunamaz.
                          </p>

                          <p>
                            <strong> ç) </strong>
                            Elektronik ticaret aracı hizmet sağlayıcı,
                            elektronik ticaret hizmet sağlayıcının tanıtıcı
                            bilgilerini, kendisinden temin ettiği belgeler
                            üzerinden veya ilgili kurumların erişime açık
                            elektronik sistemlerinden doğrular.
                          </p>

                          <p>
                            <strong> (2) </strong>
                            Bir takvim yılındaki net işlem hacmi on milyar Türk
                            lirasının üzerinde olan elektronik ticaret aracı
                            hizmet sağlayıcı, birinci fıkrada belirtilen
                            yükümlülüklere ilave olarak aşağıdaki hususlara
                            uymakla yükümlüdür:
                          </p>

                          <p>
                            <strong> a) </strong>
                            Elektronik ticaret hizmet sağlayıcı ve alıcıdan elde
                            ettiği verileri, yalnızca aracılık hizmetlerinin
                            sunulması amacıyla kullanır ve aracılık hizmeti
                            sunduğu elektronik ticaret pazar yerleri veya diğer
                            elektronik ticaret ortamlarında elektronik ticaret
                            hizmet sağlayıcılarla rekabet ederken kullanamaz.
                          </p>

                          <p>
                            <strong> b) </strong>
                            Elektronik ticaret hizmet sağlayıcının satışları
                            dolayısıyla elde ettiği verileri bedelsiz taşımasına
                            ve bu veriler ile bunlardan elde ettiği işlenmiş
                            verilere bedelsiz ve etkin şekilde erişim
                            sağlamasına teknik imkân sunar.
                          </p>

                          <p>
                            <strong> c) </strong>
                            Net işlem hacmine dâhil edilen elektronik ticaret
                            ortamları hariç olmak üzere, kendine ait elektronik
                            ticaret ortamları arasında erişim imkânı sunamaz ve
                            bu ortamlarda birbirinin tanıtımını yapamaz.
                          </p>

                          <p>
                            <strong> ç) </strong>
                            6362 sayılı Kanunun 13 üncü maddesi uyarınca
                            borsada kayden izlenen paylar hariç olmak üzere,
                            şirket ortaklarının yüzde beş ve katlarına ulaşan
                            pay devir veya edinimlerini, bu oranlara
                            ulaşılmasını sağlayan devrin veya edinimin pay
                            defterine işlendiği tarihten itibaren bir ay içinde
                            Bakanlığa bildirir.
                          </p>

                          <p>
                            <strong> d) </strong>
                            Şirket kurması, kurulmuş şirketin paylarını
                            devralması veya bu payları devretmesi hâlinde;
                            kuruluşta kuruluşun ticaret siciline tescilinden,
                            devirde ise devrin pay defterine işlendiği tarihten
                            itibaren bir ay içinde bu işlemleri Bakanlığa
                            bildirir.
                          </p>

                          <p>
                            <strong> e) </strong>
                            Sermaye Piyasası Kurulunca 6362 sayılı Kanuna göre
                            yetkilendirilen bağımsız denetim kuruluşunca
                            hazırlanan ve elektronik ticaret aracı hizmet
                            sağlayıcının faaliyetlerini, yönetim ve organizasyon
                            yapısını, mevcut pay sahipleri ve bunların pay
                            oranları ile iştirakleri ve
                            iştirakleri nezdindeki pay oranlarını, ekonomik
                            bütünlük içinde bulunduğu kişi bilgilerini, finansal
                            tablolarını içerecek şekilde finansal durumunu,
                            üçüncü fıkranın (a) ve (b) bentleri ile ek 4 üncü
                            maddede belirtilen yükümlülüklere uyumunu ve
                            yönetmelikle belirlenen diğer hususları gösterir
                            denetim raporunu Bakanlıkça belirlenen usul ve
                            esaslara göre Bakanlığa gönderir.
                          </p>

                          <p>
                            <strong> f) </strong>
                            Elektronik ticaret hizmet sağlayıcı tarafından
                            sağlanan içerikle ilgili mevzuata aykırı hususların
                            tespitine yönelik işlemleri ve bunun neticesinde
                            tespit edilen aykırılıkları içeren raporu Bakanlıkça
                            belirlenen usul ve esaslara göre Bakanlığa gönderir.
                          </p>

                          <p>
                            <strong> (3) </strong>
                            Bir takvim yılındaki net işlem hacmi otuz milyar
                            Türk lirasının ve iptal ve iadeler hariç işlem
                            sayısı yüz bin adedin üzerinde olan elektronik
                            ticaret aracı hizmet sağlayıcı, birinci ve ikinci
                            fıkralarda belirtilen yükümlülüklere ilave olarak
                            aşağıdaki hususlara uymakla yükümlüdür:
                          </p>

                          <p>
                            <strong> a) </strong>
                            Net işlem hacminin aynı takvim yılına ilişkin on iki
                            aylık ortalama Tüketici Fiyat Endeksi değişim oranı
                            uygulanmış tutarının otuz milyar Türk liralık kısmı
                            için bu tutarın yüzde ikisine, otuz milyar Türk
                            lirasının üzerindeki kısmı için ise bu tutarın binde
                            üçüne kadar reklam harcamasını izleyen takvim
                            yılında yapabilir. Buna göre hesaplanan tutar, net
                            işlem hacmine dâhil edilen elektronik ticaret aracı
                            hizmet sağlayıcılar ile bunların ekonomik bütünlüğü
                            içinde bulunup elektronik ticaret pazar yerinde
                            faaliyet gösteren elektronik ticaret hizmet
                            sağlayıcıların toplam reklam bütçesini oluşturur. Bu
                            bütçe, elektronik ticaret aracı hizmet sağlayıcı ve
                            elektronik ticaret hizmet sağlayıcılar arasında net
                            işlem hacmine katkıları oranında paylaştırılır ve
                            bir takvim yılının üçer aylık dönemlerinde en fazla
                            dörtte biri oranında kullanılabilir.
                          </p>

                          <p>
                            <strong> b) </strong>
                            Net işlem hacminin aynı takvim yılına ilişkin on iki
                            aylık ortalama Tüketici Fiyat Endeksi değişim oranı
                            uygulanmış tutarının otuz milyar Türk liralık kısmı
                            için bu tutarın yüzde ikisine, otuz milyar Türk
                            lirasının üzerindeki kısmı için ise bu tutarın binde
                            üçüne kadar promosyon, ödül, puan, kupon, hediye
                            çeki ve benzeri imkânları izleyen takvim yılında
                            sunabilir. Buna göre hesaplanan tutar, elektronik
                            ticaret aracı hizmet sağlayıcı ve ekonomik bütünlük
                            içinde bulunduğu kişiler tarafından elektronik
                            ticaret pazar yerindeki alıcı ve elektronik ticaret
                            hizmet sağlayıcılara yapılabilecek toplam indirim
                            bütçesini oluşturur ve bir takvim yılının üçer aylık
                            dönemlerinde en fazla dörtte biri oranında
                            kullanılabilir. Alıcı ve elektronik ticaret hizmet
                            sağlayıcı adına katlanılan maliyetler ile bunlara
                            sağlanan ekonomik menfaatler de bu kapsamda
                            değerlendirilir. Bakanlık, bu bende göre hesaplanan
                            tutarın mal ve hizmet sektör ve kategorileri
                            itibarıyla kullanım üst sınırını belirlemeye
                            yetkilidir.
                          </p>

                          <p>
                            <strong> c) </strong>
                            Elektronik ticaret hizmet sağlayıcının ticari
                            ilişkilerini, alternatif kanallardan aynı ya da
                            farklı fiyattan mal veya hizmet sunmasını ya da
                            reklam yapmasını kısıtlayamaz, herhangi bir kişiden
                            mal veya hizmet teminine zorlayamaz. Buna imkân
                            sağlayan herhangi bir hükme aracılık sözleşmesinde
                            yer veremez.
                          </p>

                          <p>
                            <strong> (4) </strong>
                            Bir takvim yılındaki net işlem hacmi altmış milyar
                            Türk lirasının ve iptal ve iadeler hariç işlem
                            sayısı yüz bin adedin üzerinde olan elektronik
                            ticaret aracı hizmet sağlayıcılar, birinci, ikinci
                            ve üçüncü fıkralarda belirtilen yükümlülüklere ilave
                            olarak aşağıdaki hususlara uymakla yükümlüdür:
                          </p>

                          <p>
                            <strong> a) </strong>
                            Aracılık hizmeti sunduğu elektronik ticaret pazar
                            yerlerinde, 23/2/2006 tarihli ve 5464 sayılı Banka
                            Kartları ve Kredi Kartları Kanunu kapsamında kredi
                            kartıyla yapılan kredili ödeme işlemleri ile diğer
                            ödeme işlemleri hariç olmak üzere, ekonomik bütünlük
                            içinde bulunduğu bankaların ya da 6361 sayılı Kanun
                            kapsamındaki şirketlerin, kredi verme işlemleri
                            dâhil sundukları her türlü hizmetin
                            gerçekleştirilmesine ilişkin faaliyette bulunmasına
                            imkân sağlayamaz.
                          </p>

                          <p>
                            <strong> b) </strong>
                            Aracılık hizmeti sunduğu elektronik ticaret pazar
                            yerlerinde;
                          </p>

                          <p>
                            <strong> 1) </strong>
                            Ekonomik bütünlük içinde bulunduğu 6493 sayılı Kanun
                            kapsamındaki elektronik para kuruluşlarınca ihraç
                            edilen elektronik paraların kabulüne ilişkin
                            faaliyette bulunulmasına imkân sağlayamaz.
                          </p>
                          <p>
                            <strong> 2) </strong>
                            6493 sayılı Kanunun 12 nci maddesinin ikinci
                            fıkrasının (h) bendi ile 18 inci maddesinin beşinci
                            fıkrası uyarınca 6493 sayılı Kanun kapsamı dışında
                            kalan araçlarla ilgili hizmetler sunamaz veya
                            ekonomik bütünlük içinde bulunduğu kişilerce bu
                            hizmetlerin sunulmasına imkân sağlayamaz.
                          </p>

                          <p>
                            <strong> c) </strong>
                            Aracılık hizmeti sunduğu elektronik ticaret pazar
                            yerlerindeki satışlar, kendisinin elektronik ticaret
                            hizmet sağlayıcı olarak yaptığı satışları ile
                            elektronik ticaret dışındaki satışları hariç olmak
                            üzere, 10/7/2003 tarihli ve 4925 sayılı Karayolu
                            Taşıma Kanunu kapsamında eşya taşımacılığı,
                            26/9/2011 tarihli ve 655 sayılı Ulaştırma ve Altyapı
                            Alanına İlişkin Bazı Düzenlemeler Hakkında Kanun
                            Hükmünde Kararname kapsamında taşıma işleri
                            organizatörlüğü ve 9/5/2013 tarihli ve 6475 sayılı
                            Posta Hizmetleri Kanunu kapsamında posta hizmet
                            sağlayıcılığı faaliyetlerinde bulunamaz.
                          </p>

                          <p>
                            <strong> ç) </strong>
                            Mal veya hizmet ilanlarının yayımlanmasına
                            elektronik ortam sağlaması hâlinde, aynı ortamda mal
                            veya hizmet teminine yönelik sözleşme yapılmasına ya
                            da sipariş verilmesine imkân sağlayamaz. Bu
                            hizmetlerin kendisi veya ekonomik bütünlük içinde
                            bulunduğu kişiler tarafından farklı elektronik
                            ortamlarda verilmesi hâlinde bu ortamlar arasında
                            erişim imkânı sunamaz ve birbirinin tanıtımını
                            yapamaz.
                          </p>

                          <p>
                            <strong> (5) </strong>
                            Dergi ve gazete gibi süreli yayınlar ile kitap ve
                            elektronik kitap okuyucuları hakkında birinci
                            fıkranın (a) bendi uygulanmaz.
                          </p>

                          <p>
                            <strong> (6) </strong>
                            İkinci fıkranın (a) ve (c) bentlerinin
                            uygulanmasında elektronik ticaret aracı hizmet
                            sağlayıcının ekonomik bütünlük içinde bulunduğu
                            kişiler de değerlendirmeye dâhil edilir. Dördüncü
                            fıkranın (a) ve (b) bentleri, elektronik ticaret
                            aracı hizmet sağlayıcının net işlem hacmine dâhil
                            edilen elektronik ticaret aracı hizmet sağlayıcılar
                            ile bunların ekonomik bütünlüğü içinde bulunup
                            elektronik ticaret pazar yerinde faaliyet gösteren
                            elektronik ticaret hizmet sağlayıcılar hakkında da
                            uygulanır.
                          </p>

                          <p>
                            <strong> (7) </strong>
                            Bu madde kapsamında net işlem hacmi, işlem sayısı ve
                            toplam satış hasılatının hesaplanması ile üçüncü
                            fıkranın (a) ve (b) bentlerine göre hesaplanan
                            tutarların kullanılmasında yurt dışına yönelik
                            işlemler dikkate alınmaz.
                          </p>

                          <p>
                            <strong> (8) </strong>
                            İkinci, üçüncü ve dördüncü fıkralarda belirtilen
                            hadleri aşan elektronik ticaret aracı hizmet
                            sağlayıcı hakkında, ikinci fıkranın (a), (c), (ç) ve
                            (d) bentleri ile üçüncü fıkra, hadlerin aşıldığı
                            yılı izleyen takvim yılı başından itibaren
                            uygulanır. Bu fıkralarda belirtilen diğer
                            yükümlüklere ise hadlerin aşıldığı yılı izleyen
                            takvim yılının ilk üç ayı içinde uyum sağlanır.
                            Dördüncü fıkrada belirtilen haddin aşılıp
                            aşılmadığının tespitinde yüzde beşin altındaki had
                            aşımları dikkate alınmaz. Herhangi bir takvim
                            yılında hadlerin altında kalan elektronik ticaret
                            aracı hizmet sağlayıcı hakkında, bu durum devam
                            ettiği sürece ikinci, üçüncü ve dördüncü fıkralar
                            uygulanmaz.
                          </p>

                          <p>
                            <strong> (9) </strong>
                            Ekonomik bütünlük içinde bulunan elektronik ticaret
                            aracı hizmet sağlayıcıların net işlem hacimleri ile
                            işlem sayıları toplamının dördüncü fıkranın birinci
                            cümlesinde belirtilen eşiklerin üzerinde olması
                            hâlinde, bu elektronik ticaret aracı hizmet
                            sağlayıcıların her biri hakkında dördüncü fıkranın
                            (c) bendi uygulanır.
                          </p>

                          <p>
                            <strong> (10) </strong>
                            Bu maddede belirtilen parasal eşikler ETBİS verileri
                            kullanılarak her yıl hesaplanan elektronik ticaret
                            hacminin yıllık değişim oranına göre artırılır.
                            Artırımın yapıldığı tarihten önceki takvim yılına
                            ilişkin net işlem hacmine uygulanacak bu eşikler ile
                            elektronik ticaret hacminin yıllık değişim oranı en
                            geç şubat ayı içinde Bakanlığın internet sitesinde
                            ilan edilir.
                          </p>

                          <p>
                            <strong> (11) </strong>
                            Bu madde, münhasıran elektronik ticaret aracı hizmet
                            sağlayıcının markasını taşıyan ürünlerin bayilik ve
                            acentelik gibi sözleşmeler kapsamında satışa
                            sunulduğu elektronik ticaret pazar yerleri hakkında
                            uygulanmaz.
                          </p>

                          <p>
                            <strong> (12) </strong>
                            Bir takvim yılındaki net işlem hacmi otuz milyar
                            Türk lirasının ve iptal ve iadeler hariç işlem
                            sayısı yüz bin adedin üzerinde olan elektronik
                            ticaret aracı hizmet sağlayıcının ya da bir takvim
                            yılındaki net işlem hacmi otuz milyar Türk lirasının
                            ve iptal ve iadeler hariç işlem sayısı on milyon
                            adedin üzerinde olan elektronik ticaret hizmet
                            sağlayıcının ekonomik bütünlüğü içinde bulunup bu
                            eşiklerin altında kalan ve elektronik ticaret aracı
                            hizmet sağlayıcının net işlem hacmine dâhil
                            edilmeyen elektronik ticaret aracı hizmet
                            sağlayıcılar ile ekonomik bütünlük içinde bulunup
                            mal veya hizmet ilanlarının yayımlanmasına
                            elektronik ortam sağlayanların her birinin reklam ve
                            indirim bütçeleri, üçüncü fıkranın (a) ve (b)
                            bentlerine göre hesaplanan reklam ve indirim
                            bütçelerinin her birinin yüzde yirmisinden fazla
                            olamaz.
                          </p>

                          <p>
                            <strong
                              >Elektronik ticaret hizmet sağlayıcının
                              yükümlülükleri</strong
                            >
                          </p>
                          <p>
                            <strong
                              >EK MADDE 3-  (Ek:1/7/2022-7416/9 md.)</strong
                            >
                          </p>

                          <p>
                            <strong> (1) </strong>
                            Elektronik ticaret hizmet sağlayıcı, olumlu irade
                            beyanını yazılı şekilde ya da elektronik ortamda
                            almadığı sürece, ekonomik bütünlük içinde
                            bulunmadığı kişilerin ETBİS’e kayıtlı alan adının
                            ana unsurunu oluşturan tescilli markasını kullanarak
                            çevrim içi arama motorlarında pazarlama ve tanıtım
                            faaliyetinde bulunamaz.
                          </p>
                          <p>
                            <strong> (2) </strong>
                            Ek 2 nci maddenin ikinci fıkrasının (c), (ç), (d) ve
                            (e) bentleri ile ek 4 üncü maddenin dördüncü ve
                            yedinci fıkraları dışında kalan hükümleri, bir
                            takvim yılındaki net işlem hacmi on milyar Türk
                            lirasının ve iptal ve iadeler hariç işlem sayısı on
                            milyon adedin üzerinde olan elektronik ticaret
                            hizmet sağlayıcı hakkında kıyasen uygulanır. Ek 4
                            üncü maddede düzenlenen lisans ücretinin
                            hesaplanmasında, elektronik ticaret hizmet
                            sağlayıcının kendine ait elektronik ticaret
                            ortamları üzerinden yurt dışına yaptığı satışlar
                            hesaba dâhil edilmez.
                          </p>

                          <p>
                            <strong> (3) </strong>
                            Bir takvim yılındaki net işlem hacmi otuz milyar
                            Türk lirasının ve iptal ve iadeler hariç işlem
                            sayısı on milyon adedin üzerinde olan elektronik
                            ticaret hizmet sağlayıcı hakkında ikinci fıkrada
                            belirtilen yükümlülüklere ilave olarak, ek
                            2 nci maddenin üçüncü fıkrasının (a) ve (b) bentleri
                            kıyasen uygulanır.
                          </p>

                          <p>
                            <strong> (4) </strong>
                            Bir takvim yılındaki net işlem hacmi altmış milyar
                            Türk lirasının ve iptal ve iadeler hariç işlem
                            sayısı on milyon adedin üzerinde olan elektronik
                            ticaret hizmet sağlayıcı hakkında ikinci ve üçüncü
                            fıkralarda belirtilen yükümlülüklere ilave olarak ek
                            2 nci maddenin dördüncü fıkrasının (a), (b) ve (ç)
                            bentleri kıyasen uygulanır.
                          </p>

                          <p>
                            <strong> (5) </strong>
                            Ek 2 nci maddenin yedinci ve sekizinci fıkraları, bu
                            madde kapsamındaki elektronik ticaret hizmet
                            sağlayıcılar hakkında kıyasen uygulanır.
                          </p>

                          <p>
                            <strong> (6) </strong>
                            İkinci, üçüncü, dördüncü ve yedinci fıkralardaki
                            eşikler hakkında ek 2 nci maddenin onuncu fıkrası
                            uygulanır.
                          </p>

                          <p>
                            <strong> (7) </strong>
                            Bir takvim yılındaki net işlem hacmi otuz milyar
                            Türk lirasının ve iptal ve iadeler hariç işlem
                            sayısı yüz bin adedin üzerinde olan elektronik
                            ticaret aracı hizmet sağlayıcının ya da bir takvim
                            yılındaki net işlem hacmi otuz milyar Türk lirasının
                            ve iptal ve iadeler hariç işlem sayısı on milyon
                            adedin üzerinde olan elektronik ticaret hizmet
                            sağlayıcının ekonomik bütünlüğü içinde bulunup
                            üçüncü fıkrada belirtilen eşiklerin altında kalan ve
                            aracı hizmet sağlayıcının net işlem hacmine dâhil
                            edilmeyen elektronik ticaret hizmet sağlayıcıların
                            her birinin reklam ve indirim bütçeleri, ek
                            2 nci maddenin üçüncü fıkrasının (a) ve (b)
                            bentlerine göre hesaplanan reklam ve indirim
                            bütçelerinin her birinin yüzde yirmisinden fazla
                            olamaz.
                          </p>

                          <p>
                            <strong> (8) </strong>
                            Bu madde, toplam satış hasılatının yarısından
                            fazlasını elektronik ticaret dışındaki satışlardan
                            elde eden elektronik ticaret hizmet sağlayıcılar
                            hakkında uygulanmaz.
                          </p>

                          <p>
                            <strong>Elektronik ticaret lisansı</strong>
                          </p>
                          <p>
                            <strong
                              >EK MADDE 4-  (Ek:1/7/2022-7416/10 md.)</strong
                            >
                          </p>

                          <p>
                            <strong> (1) </strong>
                            Bir takvim yılındaki net işlem hacmi on milyar Türk
                            lirasının ve iptal ve iadeler hariç işlem sayısı yüz
                            bin adedin üzerinde olan elektronik ticaret aracı
                            hizmet sağlayıcı, faaliyetine devam edebilmek için
                            Bakanlıktan lisans almak ve lisansını yenilemek
                            zorundadır. Lisans alma başvurusu, hadlerin aşıldığı
                            tarihi izleyen takvim yılının; lisans yenileme
                            başvurusu ise eşikler aşıldığı sürece her takvim
                            yılının mart ayı içinde yapılır.
                          </p>

                          <p>
                            <strong> (2) </strong>
                            Lisans ücretinin ödendiğine dair belgenin lisans
                            alma veya yenileme başvurusu sırasında ibraz
                            edilmesi hâlinde başka bir şart aranmaksızın lisans
                            verilir veya yenilenir.
                          </p>

                          <p>
                            <strong> (3) </strong>
                            Elektronik ticaret aracı hizmet sağlayıcının bir
                            takvim yılında gerçekleşen net işlem hacminin;
                          </p>

                          <p>
                            <strong> a) </strong>
                            On milyar Türk lirası ile yirmi milyar Türk lirası
                            arasında olması hâlinde on milyar Türk lirasını aşan
                            kısmının on binde üçü,
                          </p>

                          <p>
                            <strong> b) </strong>
                            Yirmi milyar Türk lirası ile otuz milyar Türk lirası
                            arasında olması hâlinde (a) bendine göre hesaplanan
                            tutara ilave olarak, yirmi milyar Türk lirasını aşan
                            kısmının binde beşi,
                          </p>

                          <p>
                            <strong> c) </strong>
                            Otuz milyar Türk lirası ile kırk milyar Türk lirası
                            arasında olması hâlinde (a) ve (b) bentlerine göre
                            hesaplanan tutarlara ilave olarak, otuz milyar Türk
                            lirasını aşan kısmının yüzde biri,
                          </p>

                          <p>
                            <strong> ç) </strong>
                            Kırk milyar Türk lirası ile elli milyar Türk lirası
                            arasında olması hâlinde (a), (b) ve (c) bentlerine
                            göre hesaplanan tutarlara ilave olarak, kırk milyar
                            Türk lirasını aşan kısmının yüzde beşi,
                          </p>

                          <p>
                            <strong> d) </strong>
                            Elli milyar Türk lirası ile elli beş milyar Türk
                            lirası arasında olması hâlinde (a), (b), (c) ve (ç)
                            bentlerine göre hesaplanan tutarlara ilave olarak,
                            elli milyar Türk lirasını aşan kısmının yüzde onu,
                          </p>

                          <p>
                            <strong> e) </strong>
                            Elli beş milyar Türk lirası ile altmış milyar Türk
                            lirası arasında olması hâlinde (a), (b), (c), (ç) ve
                            (d) bentlerine göre hesaplanan tutarlara ilave
                            olarak, elli beş milyar Türk lirasını aşan kısmının
                            yüzde on beşi,
                          </p>

                          <p>
                            <strong> f) </strong>
                            Altmış milyar Türk lirası ile altmış beş milyar Türk
                            lirası arasında olması hâlinde (a), (b), (c), (ç),
                            (d) ve (e) bentlerine göre hesaplanan tutarlara
                            ilave olarak, altmış milyar Türk lirasını aşan
                            kısmının yüzde yirmisi,
                          </p>

                          <p>
                            <strong> g) </strong>
                            Altmış beş milyar Türk lirasının üzerinde olması
                            hâlinde (a), (b), (c), (ç), (d), (e) ve (f)
                            bentlerine göre hesaplanan tutarlara ilave olarak,
                            altmış beş milyar Türk lirasını aşan kısmının yüzde
                            yirmi beşi,
                          </p>
                          <p>oranında lisans ücreti ödenir.</p>
                          <p>
                            <strong> (4) </strong>
                            Bu maddedeki eşiklerin aşılıp aşılmadığının
                            tespitinde, ekonomik bütünlük içinde bulunan
                            elektronik ticaret aracı hizmet sağlayıcıların net
                            işlem hacimleri ve işlem sayıları dikkate alınır.
                            Net işlem hacimleri ve işlem sayıları toplamının
                            birinci fıkrada belirtilen eşikleri aşması hâlinde,
                            bu elektronik ticaret aracı hizmet sağlayıcıların
                            her biri lisans alma ve yenileme başvurusunda
                            bulunur. Lisans ücreti, elektronik ticaret aracı
                            hizmet sağlayıcılardan, bunların elektronik ticaret
                            pazar yerlerinde bir önceki takvim yılında
                            gerçekleşen net işlem hacimleri oranında tahsil
                            edilir.
                          </p>

                          <p>
                            <strong> (5) </strong>
                            Lisans ücreti Bakanlıkça peşin olarak tahsil edilir.
                            Tahsil edilen tutarlar genel bütçeye gelir
                            kaydedilir.
                          </p>
                          <p>
                            <strong> (6) </strong>
                            Bu maddede belirtilen parasal eşikler her yıl ETBİS
                            verileri kullanılarak hesaplanan elektronik ticaret
                            hacminin yıllık değişim oranına göre artırılır.
                            Artırımın yapıldığı tarihten önceki takvim yılına
                            ilişkin net işlem hacmine uygulanacak bu eşikler ile
                            elektronik ticaret hacminin yıllık değişim oranı en
                            geç şubat ayı içinde Bakanlığın internet sitesinde
                            ilan edilir.
                          </p>

                          <p>
                            <strong> (7) </strong>
                            Lisans ücretinin hesaplanmasında, elektronik ticaret
                            aracı hizmet sağlayıcı ve ekonomik bütünlük içinde
                            bulunduğu elektronik ticaret aracı hizmet
                            sağlayıcıların elektronik ticaret pazar yerleri
                            üzerinden yurt dışına yapılan satışlar hesaba dâhil
                            edilmez.
                          </p>

                          <p>
                            <strong> (8) </strong>
                            Lisansın verilmesi ve yenilenmesi, lisans ücretinin
                            ödenmesi ve elektronik ticaret hacminin yıllık
                            değişim oranının belirlenmesi ile bu maddenin
                            uygulanmasına ilişkin usul ve esaslar bu Kanun
                            hükümlerine göre çıkarılan yönetmelikle düzenlenir.
                          </p>

                          <p>
                            <strong> (9) </strong>
                            Bu madde, münhasıran elektronik ticaret aracı hizmet
                            sağlayıcının markasını taşıyan ürünlerin bayilik ve
                            acentelik gibi sözleşmeler kapsamında satışa
                            sunulduğu elektronik ticaret pazar yerleri hakkında
                            uygulanmaz.
                          </p>

                          <p>
                            <strong
                              >Onay alınarak oluşturulan veri tabanları</strong
                            >
                          </p>
                          <p>
                            <strong>GEÇİCİ MADDE 1 – </strong>
                            <strong> (1) </strong>
                            Bu Kanunun yürürlüğe girdiği tarihten önce, ticari
                            elektronik ileti gönderilmesi amacıyla onay alınarak
                            oluşturulmuş olan veri tabanları hakkında 6 ncı
                            maddenin birinci fıkrası uygulanmaz.
                          </p>

                          <p>
                            <strong>İntibak süreci</strong>
                          </p>
                          <p>
                            <strong
                              >GEÇİCİ MADDE 2 -  (Ek:1/7/2022-7416/11
                              md.) </strong
                            >
                          </p>

                          <p>
                            <strong>(1)</strong> Bu maddeyi ihdas eden Kanunun
                            yayımlandığı tarih itibarıyla;
                          </p>
                          <p>
                            <strong>a)</strong> Aracılık hizmeti sunduğu
                            elektronik ticaret pazar yerlerinde kendisinin veya
                            ekonomik bütünlük içinde bulunduğu kişilerin
                            markasını taşıyan veya kullanım hakkını haiz olduğu
                            malları satışa sunan veya satışına aracılık eden
                            elektronik ticaret aracı hizmet sağlayıcılar, ek
                            2 nci maddenin birinci fıkrasının (a) bendinde,
                          </p>
                          <p>
                            <strong>b)</strong> 5411 sayılı Kanun kapsamında
                            banka veya 6493 sayılı Kanun kapsamında elektronik
                            para kuruluşu olarak yetkilendirilen işletmelerin
                            ekonomik bütünlük içinde bulunduğu elektronik
                            ticaret aracı hizmet sağlayıcılar, ek 2 nci maddenin
                            dördüncü fıkrasının (a) ve (b) bentlerinde,
                          </p>

                          <p>
                            <strong>c)</strong> 4925 sayılı Kanun kapsamında
                            eşya taşımacılığı faaliyetinde bulunan işletmeler,
                            655 sayılı Kanun Hükmünde Kararname kapsamında
                            taşıma işleri organizatörü veya 6475 sayılı Kanun
                            kapsamında posta hizmet sağlayıcı olarak
                            yetkilendirilen işletmelerin ekonomik bütünlük
                            içinde bulunduğu elektronik ticaret aracı hizmet
                            sağlayıcılar, ek 2 nci maddenin dördüncü fıkrasının
                            (c) bendinde,
                          </p>
                          <p>
                            yer alan yükümlülüklere 1/1/2024 tarihine kadar uyum
                            sağlar.
                          </p>

                          <p>
                            <strong>(2)</strong> Bu maddeyi ihdas eden Kanunun
                            yürürlüğe girdiği tarihten önce yapılan ve aynı
                            tarihten itibaren altı ay içinde bu Kanuna uygun
                            hâle getirilmeyen aracılık sözleşmelerinin ilgili
                            hükümleri geçersizdir.
                          </p>

                          <p>
                            <strong>(3)</strong> Elektronik ticaret hizmet
                            sağlayıcının ek 3 üncü, elektronik ticaret aracı
                            hizmet sağlayıcının ek 4 üncü madde kapsamındaki
                            lisans alma yükümlülüğü 1/1/2025 tarihinden itibaren
                            yerine getirilir.
                          </p>

                          <p>
                            <strong>(4)</strong> Ek 2 nci, ek 3 üncü ve ek 4
                            üncü maddelerde belirtilen eşikler, 2023 yılının
                            Şubat ayı içinde Cumhurbaşkanı tarafından yarısına
                            kadar artırılabilir.
                          </p>

                          <p><strong>Yürürlük</strong></p>
                          <p>
                            <strong>MADDE 15 – (1)</strong> Bu Kanun 1/5/2015
                            tarihinde yürürlüğe girer.
                          </p>

                          <p><strong>Yürütme</strong></p>
                          <p>
                            <strong>MADDE 16 – (1) </strong>Bu Kanun hükümlerini
                            Bakanlar Kurulu yürütür.
                          </p>
                          <br>
                          <!-- devam tablo -->
                          <p>
                            <strong>6563 SAYILI KANUNA EK VE DEĞİŞİKLİK GETİREN MEVZUATIN VEYA ANAYASA MAHKEMESİ TARAFINDAN İPTAL EDİLEN HÜKÜMLERİN YÜRÜRLÜĞE GİRİŞ TARİHİNİ GÖSTERİR LİSTE
                        </strong>
                          </p>
                          <br>
                          <p>
                            <table class="myTable">
                                <tr>
                                  <th>Değiştiren Kanunun/ KHK’nin/İptal Eden Anayasa Mahkemesi Kararının Numarası</th>
                                  <th>6563 Sayılı Kanunun Değişen veya İptal Edilen Maddeleri</th>
                                  <th>Yürürlüğe Giriş Tarihi</th>
                                </tr>
                                <tr>
                                  <td>7061</td>
                                  <td>11,12</td>
                                  <td>5/12/2017</td>
                                </tr>
                                <tr>
                                  <td rowspan="3">7416</td>
                                  <td>
                                    1, 2, 9, 10, 11, 12,
                                      Ek Madde 1, Ek Madde 2,
                                      Ek Madde 3, Ek Madde 4, Geçici Madde 2
                                  </td>
                                  <td>
                                    1/1/2023
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Ek 2 nci Madde üçüncü fıkra (a) ve (b) bentleri
                                  </td>
                                  <td>
                                    2022 takvim yılına ilişkin net işlem hacimlerine de uygulanmak üzere 1/1/2023 tarihinde
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Ek 2 nci Madde ikinci fıkra (b) bendi, onuncu fıkra,
                                    Ek 4 üncü Madde altıncı fıkra
                                  </td>
                                  <td>1/1/2024</td>
                                </tr>
                              </table>

                          </p>
                          <hr/>
                          <p>
                            <strong>(1)</strong> <i>1/7/2022 tarihli ve 7416 sayılı Kanunun 2 nci maddesiyle bu bentte yer alan “Gümrük ve” ibaresi madde metninden çıkarılmıştır.</i>
                          </p>
                          <p>
                            <strong>(2)</strong> <i>1/7/2022 tarihli ve 7416 sayılı Kanunun 5 inci maddesiyle bu maddeye birinci fıkrasından sonra gelmek üzere ikinci fıkra eklenmiş, mevcut fıkralar buna göre teselsül ettirilmiş, mevcut ikinci fıkrasında yer alan “görevlendirilen denetim elemanları” ibaresi “denetimle görevlendirilen personel” şeklinde değiştirilmiş, fıkraya “ilgili olarak” ibaresinden sonra gelmek üzere “elektronik ortamda tutulanlar dâhil olmak üzere” ibaresi eklenmiştir.</i>
                          </p>
                          <p>
                            <strong>(3)</strong> <i>1/7/2022 tarihli ve 7416 sayılı Kanunun 6 ncı maddesiyle bu maddeye ikinci fıkrasından sonra gelmek üzere fıkralar eklenmiş ve mevcut üçüncü fıkra buna göre teselsül ettirilmiştir.</i>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pagelink">
          <a
            target="_blank"
            href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=6563&MevzuatTur=1&MevzuatTertip=5"
            >İlgili kanuna mevzuat bilgi sisteminden  ulaşabilirsiniz.</a
          >
        </div>
      </div>
    </div>

    <div class="staticcolumn">
      <div>
        <h3>Mevzuat</h3>

        <div class="half">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="half">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Vue from "vue";

export default {
  name: "IysNedir",

  methods: {
    ...mapMutations("app", ["SET_MODAL"]),
  },
};
</script>

<style scoped>
.myTable {
  border : 1px solid black;
}
.myTable  th {
  border : 1px solid black;
  text-align: center;
}
.myTable  tr {
  border : 1px solid black;
  text-align: center;
}

.myTable  td {
  border : 1px solid black;
}
.staticbox:nth-child(odd) {
  flex-direction: row-reverse;
  text-align: right;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
